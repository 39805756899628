<template>
    <!--INICIO UBICACIONES-->            
    <div class="row" v-if="!deleted" >
        <div class="col-md-4" >
            <div class="input-group">
                <label for="importe-cliente-puntual">{{ $t('general.nombre') }}</label>
                <div class="input-group" >                                                
                    <input v-model="nombre" :disabled="disabled" class="form-control form-control-sm">
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group">
                <label for="valor-negociado-cia">{{ $t('general.provincia') }}</label>
                <div class="input-group">
                    <input v-model="provincias" :disabled="disabled" class="form-control form-control-sm" >
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group">
                <label for="importe-operario-puntual">{{ $t('general.cpostal') }}</label>
                <div class="input-group">                                                
                    <input v-model="cpostal" :disabled="disabled" type="text" class="form-control form-control-sm" >
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group">
                <label for="importe-operario-puntual">&nbsp;</label>
            </div>
            <div class="input-group">
                <button @click="guardar" type="button" class="btn btn-block btn-success btn-xs" >{{ $t('general.guardar') }}</button>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group">
                <label for="importe-operario-puntual">&nbsp;</label>
            </div>
            <div class="input-group">
                <button @click="eliminar" type="button" class="btn btn-block btn-danger btn-xs" >{{ $t('general.eliminar') }}</button>
            </div>
        </div>
    </div>
    <!--FIN UBICACIONES-->            
</template>
<script>
export default {
    props: ['empresa', 'coords', 'aniadir','deleted'],
    components: {
    },
    data() {
        return {
            nombre: '',
            provincia: '',
            cpostal: '',
            principal: "Principal",
            disabled: false,
            eliminado: true,
        };
    },
    methods: {
        cargar() {
           // this.eliminado = this.deleted;
            this.nombre = this.coords.descripcion;
            this.cpostal = this.coords.cps;
            this.provincia = this.coords.provincias;
            if (this.coords.descripcion == 'Principal') {
                this.disabled = true;   
            }
        },
        guardar() { 
            //guardar(id,descripcion,provincia,cpostal,coordenadas) 
            this.$parent.$parent.guardar(this.coords.id,this.nombre,this.provincia,this.cpostal,this.coords.coordenadas);
        },
        eliminar() {
            this.$parent.$parent.eliminar(this.coords.id);
        }
    },
    computed: {
    },
    mounted() {
        this.cargar();
    },
    watch: {
        empresa() {
            this.cargar();
        }
    }
};
</script>