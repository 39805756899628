<template>
    <div class="col-md-12">
        <seccion-datos titulo="Localización" tipo="warning" :botonGuardar="false">
            <div class="row">
                <div class="col-md-6">
                    <datosempresamapa :aux="aux" :empresa="empresa" :base="base" :aniadir="aniadir" />
                </div>
                <div class="col-md-6">
                    <div class="card card-primary card-outline">   
                        <div class="card-header">
                            <h2 class="card-title titulo">{{ $t('general.ubicaciones') }}</h2>
                            <div class="text-right">
                                <div class="card-tools">
                                    <div class="custom-control custom-switch ">
                                        <input type="checkbox" class="custom-control-input" id="aniadirmarcadores" :checked="aniadir" @change="cambiarclase()" >
                                        <label class="custom-control-label" for="aniadirmarcadores">Añadir Marcadores</label>
                                    </div>
                                    <div class="custom-control custom-switch ">
                                        <input type="checkbox" class="custom-control-input" id="basemarcadores" :checked="base" @change="cambiarbase()" >
                                        <label class="custom-control-label" for="basemarcadores">Añadir Localizacion Principal</label>
                                    </div>
                                </div> 
                            </div>
                        </div>   
                        <div class="card-body ">
                            <datos-empresa-ubicaciones-datos :deleted="deleted" v-for="coords in empresa"   :key="coords" :coords="coords" :empresa="empresa" :base="base" :aniadir="aniadir" /> 
                        </div>            
                    </div>
                </div>
            </div>
        </seccion-datos>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import DatosEmpresaMapa from './DatosEmpresaMapa.vue';
import DatosEmpresaUbicacionesDatos from './DatosEmpresaUbicacionesDatos.vue';
export default {
    components: {
        'datosempresamapa': DatosEmpresaMapa,
        'datos-empresa-ubicaciones-datos': DatosEmpresaUbicacionesDatos
    },
    data() {
        return {
            empresa: [],
            aniadir: false,
            base: false,
            deleted: false,
            contador: '',
            aux:false,
        }
    },
    methods: {
        // modulos/pwgsapi/index.php/empresa/1/coordenadas
        async obtenerEmpresa() {
            const api = new PwgsApi;
            const datos = await api.get('empresa/1/coordenadas');
            this.empresa = datos.datos;
            this.datosempresa = this.$t('general.datosempresa'); 
        },
        cambiarclase() {
            this.aniadir = !this.aniadir;
        },
        cambiarbase() {
            this.base = !this.base;
        },
        modificarcontador() { 
            this.contador = this.contador + 1;
        },
        async eliminar(id) { 
            const api = new PwgsApi;
            await api.delete('empresa/1/coordenadas/' + id);
            this.obtenerEmpresa();
            this.aux = true;
            setTimeout(() => {
                this.aux = false;
            }, 400);    
        },
        async guardar(id,descripcion,provincia,cpostal,coordenadas) {
            const subidadatos = {descripcion: descripcion,provincias:provincia,cps:cpostal,coordenadas:coordenadas };
            const api = new PwgsApi;
            try {
                await api.put('empresa/1/coordenadas/' + id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.obtenerEmpresa();
            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    mounted() {
        this.obtenerEmpresa();
    },
    watch: {
        contador() {
            this.obtenerEmpresa();
        }
    }
}
</script>
<style>
.checkboxpers{
padding-left: 3rem;
}
.titulo{
line-height: 2;
}
</style>
